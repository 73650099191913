import React, { useState } from 'react'

import './Paintings.css'

import paintings from '../../assets/paintings'

const Paintings = ({ setSelectedPainting }) => {

  return (
    <div className="Outer-container">
      <div className="Paintings-container">
        <div className="Paintings-row">
          { paintings.map(painting => (
            <div
              className="Painting-entry"
              key={`painting_${painting.name}`}
              onClick={() => {
                setSelectedPainting(painting)
              }}
            >
              <img src={painting.src} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Paintings
