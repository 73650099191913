import React from 'react'

import './Contact.css'

const Contact = () => {
  return (
    <div className="Contact-container">
      <div></div>
        <p className="Contact-text">
          mikecpaints@gmail.com
        </p>
      <div>
        <p className="Contact-text">
          (518) 703-5785
        </p>
      </div>
    </div>
  )
}

export default Contact
