import React from 'react'

import './Home.css'

import home_painting from '../../assets/paintings/chatham_crossing.jpg'

const Home = () => (
  <div className="Home-container">
    <img src={home_painting} />
  </div>
)

export default Home
