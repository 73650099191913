import React from 'react'

import './Header.css'

const Header = ({
  navButtonPress,
}) => (
  // <div style={{ 'background-color': 'red' }}>
  <div className="Header-container">
    <div className="Header-name">
      <h1>MICHAEL COHEN</h1>
    </div>
    <div className="Topnav-row">
      <button className="Topnav-button" onClick={() => navButtonPress('home')}>
        <p>Home</p>
      </button>
      <div className="Topnav-button" onClick={() => navButtonPress('paintings')}>
        <p>Paintings</p>
      </div>
      <div className="Topnav-button" onClick={() => navButtonPress('contact')}>
        <p>Contact</p>
      </div>
    </div>
  </div>
)

export default Header
