import React, { useState } from 'react'

import Header from './Header/Header'
import Footer from './Footer/Footer'
import Scrim from './Scrim'
import Home from './Home/Home'
import Paintings from './Paintings/Paintings'
import PaintingPopup from './Paintings/PaintingPopup'
import Contact from './Contact/Contact'

import './Main.css'
import paintings from '../assets/paintings'

const renderSelectedSection = (section, setSelectedPainting) => {
  switch (section) {
    case 'home':
      return <Home />
    case 'paintings':
      return <Paintings setSelectedPainting={setSelectedPainting} />
    case 'contact':
      return <Contact />
    default:
      return null
  }
}

const Main = () => {
  const [section, setSection] = useState('home')
  const [selectedPainting, setSelectedPainting] = useState()
  const showPopup = !!selectedPainting

  return (
    <div className="Main-container">
      <Header 
        navButtonPress={setSection}
      />
      <div className="Paintings-section">
        {renderSelectedSection(section, setSelectedPainting)}
      </div>
      {/* <div className="Footer-section">
        <Footer />
      </div> */}
      <Scrim
        visible={showPopup}
        clearSelectedPainting={() => setSelectedPainting(null)} />
      <PaintingPopup
        painting={selectedPainting}
        visible={showPopup}
      />
    </div>
  )
}

export default Main