import React from 'react'
import { isEmpty } from 'lodash'

import './PaintingPopup.css'

import paintings from '../../assets/paintings'

const mediumText = (mediumKey) => {
  switch (mediumKey) {
    case 'G':
      return 'Gouache on Paper'
    case 'O':
      return 'Oil on Canvas'
    default:
      return ''
  }
}

const PaintingPopup = ({ painting, visible }) => {
  return (
    visible && (
    <div className={painting.shape === 'sq' ? "Frame-container-square" : "Frame-container"}>
      <div className={painting.shape === 'sq' ? "Image-container-square" : "Image-container"}>
        { painting && <img src={painting.srcLarge} /> }
      </div>
      <div className={painting.shape === 'sq' ? "Image-details-square" : "Image-details"}>
        {painting.name}
        {!isEmpty(painting.size) ? ` • ${painting.size}` : ''}
        {!isEmpty(painting.medium) ? ` • ${mediumText(painting.medium)}` : ''}
        {painting.sold ? ` • SOLD` : ''}
      </div>
    </div>
    )
  )
}

export default PaintingPopup

