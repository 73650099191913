import afternoon_shadow_small from './paintings/afternoon_shadow_small.jpg'
import at_rest_small from './paintings/at_rest_small.jpg'
import bay_park_small from './paintings/bay_park_small.jpg'
import berkshire_winter_small from './paintings/berkshire_winter_small.jpg'
import cemetery_road_small from './paintings/cemetery_road_small.jpg'
import chatham_crossing_small from './paintings/chatham_crossing_small.jpg'
import early_morning_light_small from './paintings/early_morning_light_small.jpg'
import early_snow_small from './paintings/early_snow_small.jpg'
import end_of_the_jetty_small from './paintings/end_of_the_jetty_small.jpg'
import entering_chatham_small from './paintings/entering_chatham_small.jpg'
import fading_light_small from './paintings/fading_light_small.jpg'
import february_afternoon_small from './paintings/february_afternoon_small.jpg'
import fire_in_the_sky_small from './paintings/fire_in_the_sky_small.jpg'
// import grandma_and_sammy_small from './paintings/grandma_and_sammy_small.jpg'
import high_tide_small from './paintings/high_tide_small.jpg'
import january_small from './paintings/january_small.jpg'
// import kew_forest_small from './paintings/kew_forest_small.jpg'
import lake_at_sunset_small from './paintings/lake_at_sunset_small.jpg'
import light_through_the_trees_small from './paintings/light_through_the_trees_small.jpg'
import near_west_hill_small from './paintings/near_west_hill_small.jpg'
import new_lebanon_small from './paintings/new_lebanon_small.jpg'
import red_barn_small from './paintings/red_barn_small.jpg'
import route_20_small from './paintings/route_20_small.jpg'
import shaker_01_small from './paintings/shaker_01_small.jpg'
import shaker_02_small from './paintings/shaker_02_small.jpg'
import shaker_03_small from './paintings/shaker_03_small.jpg'
import shaker_05_small from './paintings/shaker_05_small.jpg'
import shaker_06_small from './paintings/shaker_06_small.jpg'
import splash_small from './paintings/splash_small.jpg'
import steps_to_the_garden_small from './paintings/steps_to_the_garden_small.jpg'
import suburbia_small from './paintings/suburbia_small.jpg'
import the_barn_on_66_small from './paintings/the_barn_on_66_small.jpg'
import the_crossing_at_sunset_small from './paintings/the_crossing_at_sunset_small.jpg'
// import the_front_lawn_small from './paintings/the_front_lawn_small.jpg'
import the_path_to_the_river_small from './paintings/the_path_to_the_river_small.jpg'
// import the_variegated_fig_small from './paintings/the_variegated_fig_small.jpg'
import the_warwick_crossing_small from './paintings/the_warwick_crossing_small.jpg'
import tip_of_the_jetty_small from './paintings/tip_of_the_jetty_small.jpg'
import tools_01_small from './paintings/tools_01_small.jpg'
import tools_02_small from './paintings/tools_02_small.jpg'
import tools_03_small from './paintings/tools_03_small.jpg'
import winter_01_small from './paintings/winter_01_small.jpg'
// import tools_of_the_trade_small from './paintings/tools_of_the_trade_small.jpg'
import trees_in_the_meadow_small from './paintings/trees_in_the_meadow_small.jpg'
import where_5a_meets_20_small from './paintings/where_5a_meets_20_small.jpg'

import afternoon_shadow from './paintings/afternoon_shadow.jpg'
import at_rest from './paintings/at_rest.jpg'
import bay_park from './paintings/bay_park.jpg'
import berkshire_winter from './paintings/berkshire_winter.jpg'
import cemetery_road from './paintings/cemetery_road.jpg'
import chatham_crossing from './paintings/chatham_crossing.jpg'
import early_morning_light from './paintings/early_morning_light.jpg'
import early_snow from './paintings/early_snow.jpg'
import end_of_the_jetty from './paintings/end_of_the_jetty.jpg'
import entering_chatham from './paintings/entering_chatham.jpg'
import fading_light from './paintings/fading_light.jpg'
import february_afternoon from './paintings/february_afternoon.jpg'
import fire_in_the_sky from './paintings/fire_in_the_sky.jpg'
// import grandma_and_sammy from './paintings/grandma_and_sammy.jpg'
import high_tide from './paintings/high_tide.jpg'
import january from './paintings/january.jpg'
// import kew_forest from './paintings/kew_forest.jpg'
import lake_at_sunset from './paintings/lake_at_sunset.jpg'
import light_through_the_trees from './paintings/light_through_the_trees.jpg'
import near_west_hill from './paintings/near_west_hill.jpg'
import new_lebanon from './paintings/new_lebanon.jpg'
import red_barn from './paintings/red_barn.jpg'
import route_20 from './paintings/route_20.jpg'
import shaker_01 from './paintings/shaker_01.jpg'
import shaker_02 from './paintings/shaker_02.jpg'
import shaker_03 from './paintings/shaker_03.jpg'
import shaker_05 from './paintings/shaker_05.jpg'
import shaker_06 from './paintings/shaker_06.jpg'
import splash from './paintings/splash.jpg'
import steps_to_the_garden from './paintings/steps_to_the_garden.jpg'
import suburbia from './paintings/suburbia.jpg'
import the_barn_on_66 from './paintings/the_barn_on_66.jpg'
import the_crossing_at_sunset from './paintings/the_crossing_at_sunset.jpg'
// import the_front_lawn from './paintings/the_front_lawn.jpg'
import the_path_to_the_river from './paintings/the_path_to_the_river.jpg'
// import the_variegated_fig from './paintings/the_variegated_fig.jpg'
import the_warwick_crossing from './paintings/the_warwick_crossing.jpg'
import tip_of_the_jetty from './paintings/tip_of_the_jetty.jpg'
import tools_01 from './paintings/tools_01.jpg'
import tools_02 from './paintings/tools_02.jpg'
import tools_03 from './paintings/tools_03.jpg'
import winter_01 from './paintings/winter_01.jpg'
import trees_in_the_meadow from './paintings/trees_in_the_meadow.jpg'
import where_5a_meets_20 from './paintings/where_5a_meets_20.jpg'

export default [
  { name: 'Afternoon Shadow', year: '2009', size: '18x24', medium: 'G', sold: true, src: afternoon_shadow_small, srcLarge: afternoon_shadow },
  { name: 'At Rest', year: '', size: '', medium: 'G', src: at_rest_small, srcLarge: at_rest },
  { name: 'Bay Park', year: '2008', size: '18x24', medium: 'G', src: bay_park_small, srcLarge: bay_park },
  { name: 'Berkshire Winter', year: '2008', size: '18x24', medium: 'G', src: berkshire_winter_small, srcLarge: berkshire_winter },
  { name: 'Cemetery Road', year: '', size: '', medium: 'G', src: cemetery_road_small, srcLarge: cemetery_road },
  { name: 'Chatham Crossing', year: '2008', size: '18x24', medium: 'G', sold: true, src: chatham_crossing_small, srcLarge: chatham_crossing },
  { name: 'Early Morning Light', year: '2008', size: '18x24', medium: 'G', src: early_morning_light_small, srcLarge: early_morning_light },
  { name: 'Early Snow', year: '2008', size: '18x24', medium: 'G', src: early_snow_small, srcLarge: early_snow },
  { name: 'End of the Jetty', year: '', size: '', medium: 'G', src: end_of_the_jetty_small, srcLarge: end_of_the_jetty },
  { name: 'Entering Chatham', year: '2009', size: '20x30', medium: 'G', src: entering_chatham_small, srcLarge: entering_chatham },
  { name: 'Fading Light', year: '', size: '18x24', medium: 'G', src: fading_light_small, srcLarge: fading_light },
  { name: 'February Afternoon', year: '2009', size: '20x28', medium: 'G', sold: true, src: february_afternoon_small, srcLarge: february_afternoon },
  { name: 'Fire in the Sky', year: '2010', size: '20x30', medium: 'G', src: fire_in_the_sky_small, srcLarge: fire_in_the_sky },
  // { name: 'Grandma and Sammy', year: '', size: '', medium: 'G', src: grandma_and_sammy_small, srcLarge: grandma_and_sammy },
  { name: 'High Tide', year: '', size: '', medium: 'G', src: high_tide_small, srcLarge: high_tide },
  { name: 'January', year: '2009', size: '20x24', medium: 'G', src: january_small, srcLarge: january },
  // { name: 'Kew Forest', src: kew_forest_small, srcLarge: kew_forest },
  { name: 'Lake at Sunset', year: '2011', size: '22x30', medium: 'G', src: lake_at_sunset_small, srcLarge: lake_at_sunset },
  { name: 'Light Through the Trees', year: '', size: '18x24', medium: 'G', src: light_through_the_trees_small, srcLarge: light_through_the_trees },
  { name: 'Near West Hill', year: '2011', size: '18x24', medium: 'G', src: near_west_hill_small, srcLarge: near_west_hill },
  { name: 'New Lebanon', year: '', size: '14x20', medium: 'G', src: new_lebanon_small, srcLarge: new_lebanon },
  { name: 'Red Barn', year: '2011', size: '18x24', medium: 'G', src: red_barn_small, srcLarge: red_barn },
  { name: 'Route 20', year: '', size: '14x20', medium: 'G', src: route_20_small, srcLarge: route_20 },
  { name: 'Shaker 01', year: '2012', size: '20x30', medium: 'G', src: shaker_01_small, srcLarge: shaker_01 },
  { name: 'Shaker 02', year: '2012', size: '20x30', medium: 'G', src: shaker_02_small, srcLarge: shaker_02 },
  { name: 'Shaker 03', year: '2012', size: '20x30', medium: 'G', src: shaker_03_small, srcLarge: shaker_03 },
  { name: 'Shaker 05', year: '2012', size: '20x30', medium: 'G', src: shaker_05_small, srcLarge: shaker_05 },
  { name: 'Shaker 06', year: '2012', size: '30x36', medium: 'O', src: shaker_06_small, srcLarge: shaker_06 },
  { name: 'Splash', year: '', size: '', medium: 'G', src: splash_small, srcLarge: splash },
  { name: 'Steps to the Garden', year: '2009', size: '18x24', medium: 'G', sold: true, src: steps_to_the_garden_small, srcLarge: steps_to_the_garden },
  { name: 'Suburbia', year: '', size: '', medium: 'G', src: suburbia_small, srcLarge: suburbia },
  { name: 'The Barn on 66', year: '', size: '', medium: 'G', src: the_barn_on_66_small, srcLarge: the_barn_on_66 },
  { name: 'The Crossing at Sunset ', year: '', size: '', medium: 'G', src: the_crossing_at_sunset_small, srcLarge: the_crossing_at_sunset },
  // { name: 'The Front Lawn', year: '', size: '', medium: 'G', src: the_front_lawn_small, srcLarge: the_front_lawn },
  { name: 'The Path to the River', year: '2009', size: '18x24', medium: 'G', sold: true, src: the_path_to_the_river_small, srcLarge: the_path_to_the_river },
  // { name: 'The Variegated Fig', year: '', size: '', medium: 'G', src: the_variegated_fig_small, srcLarge: the_variegated_fig },
  { name: 'The Warwick Crossing', year: '', size: '', medium: 'G', src: the_warwick_crossing_small, srcLarge: the_warwick_crossing },
  { name: 'Tip of the Jetty', year: '', size: '', medium: 'G', src: tip_of_the_jetty_small, srcLarge: tip_of_the_jetty },
  { name: 'Where 5A Meets 20', year: '', size: '', medium: 'G', src: where_5a_meets_20_small, srcLarge: where_5a_meets_20 },
  { name: 'Trees in the Meadow', year: '', size: '', medium: 'G', src: trees_in_the_meadow_small, srcLarge: trees_in_the_meadow },
  { name: 'Winter 1', year: '', size: '20x30', medium: 'G', src: winter_01_small, srcLarge: winter_01 },

  { name: 'Tools 1', year: '', size: '5ft x 5ft', medium: '', shape: 'sq', src: tools_01_small, srcLarge: tools_01 },
  { name: 'Tools 2', year: '', size: '5ft x 5ft', medium: '', shape: 'sq', src: tools_02_small, srcLarge: tools_02 },
  { name: 'Tools 3', year: '', size: '5ft x 5ft', medium: '', shape: 'sq', src: tools_03_small, srcLarge: tools_03 },
  // { name: 'Tools of the Trade', year: '', size: '', medium: 'G', src: tools_of_the_trade_small, srcLarge: tools_of_the_trade },
]